<template>
	<section class="join-donor">
		<div class="max-width-1000">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/join">加入金枝</el-breadcrumb-item>
				<el-breadcrumb-item>金枝椅友</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<quote-title label="金枝椅友" />
			<article>
				<h2 class="theme_text">播種金枝，花開藝術一畝田</h2>
				<p>金枝的願景，是成為代表台灣文化的優質戲劇品牌。我們期許一如其名，從台灣的土地文化孕育出生，穿越豐地底層，長成豐穗飽滿的「金枝」。</p>
				<p>為能給予劇團營運創作更大的力量支柱，2002年9月，【金枝椅仔會】成立了。透過愛護金枝的各界朋友熱情支持與推動，至今，金枝的年收入有三分之一是來自金枝椅仔會和民間友人的捐款贊助。椅仔會更成為金枝持續深耕台灣藝術文化、以戲劇形式積極回饋社會的最堅實後盾。</p>
				<p>深切懇盼您一起加入【金枝椅仔會】。您給予金枝的這份力量，將轉化為我們前進的動能，讓金枝得以繼續將我們最珍視的文化價值，透過劇團、作品、和演出表達出來。從金枝看見台灣，也看見台灣這片土地可以凝聚的，最美好而感動的藝術力量。</p>
				<h3 class="theme_text">誠摯邀請您加入【金枝椅仔會】
					<br>年度捐款1000元(含)以上，即可享有金枝椅友專屬權益。
					<br>金枝演社，由衷感謝您的支持！</h3>
			</article>
			<br>
			<article>
				<h2 class="theme_text">金枝椅友權益</h2>
				<hr>
				<p>感謝您的贊助，金枝將提供如下的優惠與服務：
					<ol>
						<li>您的名銜將刊印於金枝節目單及相關出版品的感謝頁。</li>
						<li>致贈專屬金枝椅友卡。</li>
						<li>訂購金枝主辦之演出節目票券及周邊出版品，享最優惠折扣。</li>
						<li>金枝主辦之演出節目享優先預購及選位。</li>
						<li>優先收到金枝最新演出及活動訊息（手機簡訊、電子報、DM）。</li>
						<li>參加金枝主辦之表演／推廣課程享有特別優惠。</li>
						<li>邀請參與金枝安排的相關活動（觀摩彩排預演、椅友特別聚會、導覽或幕後活動等）。</li>
						<li>為您開立可供年度申報抵稅的捐款收據。</li>
					</ol>
				</p>
			</article>
			<br>
			<article>
				<h2 class="theme_text">捐款方式</h2>
				<hr>
				<ol>
					<li>郵政劃撥：
						<br>帳號: 19698402
						<br>戶名: 金枝演社劇團
						<br>※ 請於備註欄位填寫「金枝椅仔會捐款」</li>
					<li>銀行匯款／ATM轉帳：
						<br>銀行別: 合作金庫銀行（006）大稻埕分行
						<br>帳號: 0050 717 134653
						<br>※ 請於匯款後來電或email告知匯款帳號末5碼</li>
					<li>支票：
						<br>支票抬頭請開列：金枝演社劇團
						<br>請以掛號郵寄至「24945新北市八里區龍米路二段117巷39-1號，金枝演社劇團收」。</li>
				</ol>
				<h3 class="theme_text">感謝您加入金枝椅友！</h3>
				<p>您可以選擇線上填表，並請填寫下列資料 或 <a href="/GBT_support.doc" target="_blank" download="金枝椅友捐款單.doc" class="theme_text"><b><u>下載捐款單</u></b></a>，以傳真或E-mail回覆給我們，若有任何需求，煩請與我們聯繫，金枝演社隨時為您服務。</p>
				<p>金枝椅友服務專線：<a href="tel:+886-2-6637-7987"><u>(02) 6637-7987</u></a> 洪筱欣
					<br>Email：<a href="mailto:gbt1993@gmail.com"><u>gbt1993@gmail.com</u></a>
					<br>Fax：02-2618-6842</p>
			</article>
			<br>
			<article>
				<h2 class="theme_text">我要加入</h2>
				<hr>
				<br>
				<member-form-readonly />
				<donor-form ref="ruleForm" />
				<br>
				<div class="center">
					<el-button type="theme" round @click="handleApplyUpgrade()">立即加入</el-button>
				</div>
			</article>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	components: {
		MemberFormReadonly: () => import("@/components/join/member-form-readonly"),
		DonorForm: () => import("@/components/join/donor-form")
	},
	methods: {
		...mapActions("auth", ["_applyUpgrade"]),
		async handleApplyUpgrade() {
			try {
				const { message } = await this._applyUpgrade(await this.$refs["ruleForm"].validate())
				this.$alert(message, {
					type: "success",
					dangerouslyUseHTMLString: true
				}).then(() => {
					this.$router.push("/join");
				});
			} catch (error) {
				this.$message.error(error);
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.join-donor {
	padding: 50px 5%;
}
</style>